import React from "react";
import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoIosCalendar,
} from "react-icons/io";

import { Container, Wrapper, Row, Box } from "../util";
import { Loader } from "../feedback";
import {
  CallToAction,
  AuthorCard,
  ResourceCard,
  Breadcrumb,
  Sidebar,
} from "../site";
import Layout from "../layout";

import Seo from "../seo";

const SocialShare = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 25px;
  }

  > * + * {
    margin-left: 15px;
  }
`;

const PostContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: ${(props) => props.theme.colours.white};

  .published-date {
    color: #777;
  }
`;

const PostImage = styled.div`
  max-width: 600px;

  > .gatsby-image-wrapper {
    max-width: 100%;
  }
`;

const PostContent = styled.div`
  padding-bottom: 30px;

  img {
    display: inline-block;
  }
`;

const CardRow = styled(Row)`
  width: calc(100% + 30px);
  align-items: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const ResourcePost = (props) => {
  const website_url = process.env.GATSBY_SITE_URL;

  const post = props.post;

  const publishedDate = new Date(post.publishedDate);
  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = publishedDate.toLocaleDateString("en-US", dateFormat);

  let resourceImage, content;

  if (props.isTemplate) {
    resourceImage = (
      <GatsbyImage
        className="-rounded -addShadow"
        image={post.resourceImage.gatsbyImageData}
        alt={`${post.resourceTitle} | ${post.resourceType}`}
        title={`${post.resourceTitle} | ${post.resourceType}`}
      />
    );

    content = renderRichText(post.resourceContent, props.contentOptions);
  } else {
    resourceImage = post.resourceImage && (
      <img
        className="-center -rounded -addShadow"
        src={post.resourceImage.fields.file.url}
        alt="Preview Resource"
        css={{ width: "100%" }}
      />
    );

    content = documentToReactComponents(
      post.resourceContent,
      props.contentOptions
    );
  }

  const handleTagClick = (e) => {
    window.sessionStorage.setItem("blog_search", e.target.innerHTML);
  };

  const handleShare = function(e) {
    if (typeof window !== "undefined") {
      if (e.target.name === "facebook") {
        window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" +
            website_url +
            "/blog/" +
            post.url,
          "_blank",
          "width=555,height=760"
        );
      }

      if (e.target.name === "twitter") {
        window.open(
          "https://twitter.com/intent/tweet?text=" +
            encodeURIComponent(post.resourceTitle) +
            "&url=" +
            website_url +
            "/blog/" +
            post.url,
          "_blank",
          "width=732,height=800"
        );
      }

      if (e.target.name === "linkedin") {
        window.open(
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
            website_url +
            "/blog/" +
            post.url +
            "&title=" +
            encodeURIComponent(post.resourceTitle),
          "_blank",
          "width=555,height=555"
        );
      }
    }
  };

  return (
    <Layout>
      <Seo
        title={
          post.resourceTitle
            ? post.resourceTitle + "| PayHero"
            : "Loading Post Preview"
        }
        description={post.excerpt}
        image={
          post.resourceImage?.file ? "https:" + post.resourceImage.file.url : ""
        }
        pathname={props.location.pathname}
        author={
          post.guestAuthorTitle
            ? post.guestAuthorTitle
            : post.author
            ? post.author
            : ""
        }
      />
      {post.resourceTitle ? (
        <>
          <Breadcrumb
            parentPage="Resources"
            parentPageLink="/blog"
            currentPage={post.resourceTitle}
          />
          <PostContainer>
            <Wrapper className="-center" maxWidth={1200} stackGap={70}>
              <Row stackGap={80}>
                <Box size={70} stackGap={60}>
                  <Box className="-textCenter" stackGap={10}>
                    <h6>
                      <div
                        className={
                          `badge -sm ` +
                          (post.resourceTopic
                            ? "-" +
                              post.resourceTopic.replace(/[^A-Z0-9]/gi, "")
                            : "")
                        }
                      >
                        {post.resourceTopic}
                      </div>
                    </h6>
                    <h1>{post.resourceTitle}</h1>
                    {post.resourceType !== "Guide" && (
                      <h6 className="published-date">
                        <IoIosCalendar css={{ margin: "0px 7px -2px 0" }} />
                        {formattedDate}
                      </h6>
                    )}
                  </Box>
                  <Box stackGap={35}>
                    {post.resourceImage &&
                      post.resourceType !== "Video" &&
                      !post.hideImage && (
                        <PostImage className="-center">
                          {resourceImage}
                        </PostImage>
                      )}
                    <Row alignCenter stackGap={30}>
                      <AuthorCard
                        guestImage={post.guestAuthorImage}
                        author={
                          post.guestAuthorTitle
                            ? post.guestAuthorTitle
                            : post.author
                        }
                        role={post.guestAuthorRole && post.guestAuthorRole}
                      />
                      <SocialShare>
                        <a
                          href="#!"
                          name="facebook"
                          data-tooltip="Share on Facebook"
                          onClick={handleShare}
                        >
                          <IoLogoFacebook css={{ pointerEvents: "none" }} />
                        </a>
                        <a
                          href="#!"
                          name="twitter"
                          data-tooltip="Share on Twitter"
                          onClick={handleShare}
                        >
                          <IoLogoTwitter css={{ pointerEvents: "none" }} />
                        </a>
                        <a
                          href="#!"
                          name="linkedin"
                          data-tooltip="Share on LinkedIn"
                          onClick={handleShare}
                        >
                          <IoLogoLinkedin css={{ pointerEvents: "none" }} />
                        </a>
                      </SocialShare>
                    </Row>
                    <hr />
                  </Box>
                  <Box stackGap={50}>
                    <PostContent className="font-bump">{content}</PostContent>
                    {post.tags && (
                      <Box>
                        {post.tags.map((i) => {
                          return (
                            <Link
                              to="/blog"
                              css={{
                                display: "inline-block",
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                              key={i}
                              onClick={handleTagClick}
                            >
                              <span
                                className="badge -grey"
                                css={{ textTransform: "capitalize" }}
                                key={i}
                              >
                                {i}
                              </span>
                            </Link>
                          );
                        })}
                      </Box>
                    )}
                    <Row stackGap={60} alignCenter>
                      <AuthorCard
                        guestImage={post.guestAuthorImage}
                        author={
                          post.guestAuthorTitle
                            ? post.guestAuthorTitle
                            : post.author
                        }
                        role={post.guestAuthorRole && post.guestAuthorRole}
                      />
                      <SocialShare>
                        <a
                          href="#!"
                          name="facebook"
                          data-tooltip="Share on Facebook"
                          onClick={handleShare}
                        >
                          <IoLogoFacebook css={{ pointerEvents: "none" }} />
                        </a>
                        <a
                          href="#!"
                          name="twitter"
                          data-tooltip="Share on Twitter"
                          onClick={handleShare}
                        >
                          <IoLogoTwitter css={{ pointerEvents: "none" }} />
                        </a>
                        <a
                          href="#!"
                          name="linkedin"
                          data-tooltip="Share on LinkedIn"
                          onClick={handleShare}
                        >
                          <IoLogoLinkedin css={{ pointerEvents: "none" }} />
                        </a>
                      </SocialShare>
                    </Row>
                  </Box>
                </Box>
                <Box size={30} css={{ position: "sticky", top: "80px" }}>
                  <Sidebar />
                </Box>
              </Row>
              {post.relatedArticles && (
                <>
                  <hr />
                  <Box stackGap={50}>
                    <Row alignCenter stackGap={30} centerOnMobile>
                      <h4 className="-fontBold h2">Keep reading</h4>
                      <Link className="link-floating" to="/blog">
                        All Resources
                      </Link>
                    </Row>
                    <CardRow isWrap justify="flex-start">
                      {post.relatedArticles.map((edge, i) => {
                        return (
                          <ResourceCard
                            isFeatured={false}
                            key={i}
                            image={
                              edge.resourceImage &&
                              edge.resourceImage.gatsbyImageData
                            }
                            topic={edge.resourceTopic}
                            title={edge.resourceTitle}
                            url={edge.url}
                            show={true}
                          />
                        );
                      })}
                    </CardRow>
                  </Box>
                </>
              )}
            </Wrapper>
          </PostContainer>
          <CallToAction />
        </>
      ) : (
        <Container>
          <Wrapper stackGap={200}>
            <Box>
              <Loader show={true} />
              <p
                css={{
                  top: "50px",
                  position: "relative",
                  marginBottom: "40px",
                  color: "#999",
                }}
                className="-textCenter"
              >
                Loading Post Preview...
              </p>
            </Box>
          </Wrapper>
        </Container>
      )}
    </Layout>
  );
};
